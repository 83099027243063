<template>
    <div class="Header" :class="{fHeader:!$store.state.flag}">
        <div class="left" >
            <img :src="$store.state.flag?require('../assets/logo.png'):require('../assets/logo1.png')" alt="">
        </div>
        <div class="right">
            <div>
                <ul>
                    <router-link to="/home"><li :style="{color:$store.state.flag?'#ffffff':'#000'}">首页</li></router-link>
                    <router-link to="/apply"><li :style="{color:$store.state.flag?'#ffffff':'#000'}">行业应用</li></router-link>
                    <router-link to="/case"><li :style="{color:$store.state.flag?'#ffffff':'#000'}">实际案例</li></router-link>
                    <router-link to="/about"><li :style="{color:$store.state.flag?'#ffffff':'#000'}">关于我们</li></router-link>
                </ul>
            </div>
            <div>
                <img src="../assets/call.png" alt="">
                <p :style="{color:$store.state.flag?'#ffffff':'#000',paddingLeft:10+'px'}">17852008921</p>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Top',
    data(){
        return{
            logoUrl:require('../assets/logo.png')
        }
    },
    components: {

    }
}
</script>
    
<style scoped>
.router-link-active >li{
    color: #4F99FD !important;
}
.router-link-active>li{
    border-bottom: 4px solid #4F99FD !important;
}
a{
    color: #FFFFFF;
    text-decoration:none
}
.Header {
    position:fixed;
    min-width: 1400px;
    z-index: 2;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
    top: 0px;
    background: url("../assets/title.png");
    /* background: rgb(66, 66, 66); */
    height: 66px;
}
.fHeader{
    background: url("../assets/title2.png") !important;
    box-shadow:0px 3px 15px rgba(177, 177, 177, 0.5);
    width: 100%;
    height: 66px;
}

.right {
    display: flex;
    height: 66px;
    justify-content: center;
    align-items: center;
   

}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    list-style-type: none;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    height: 42px;
}
li:hover{
    border-bottom: 4px solid #4F99FD;;
    color: #4F99FD !important;
}

.right>div:nth-child(1) {
    height: 100%;
    box-sizing: border-box;
    padding-right: 170px;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
}

.right>div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
}
</style>
    