// Vue Router 4
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "../components/Home"
import Apply from "../components/Apply"
import Case from "../components/Case"
import About from "../components/About"

export default createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: 'active',//配置高亮
  routes: [
    {
        path: '/',
        name: '',
        children: [
            {
              path:'/',
              redirect:'/home',//重定向
            },
        ]    
       
        
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    }
    ,
    {
        path: '/apply',
        name: 'apply',
        component: Apply
    }
    ,
    {
        path: '/case',
        name: 'case',
        component: Case
    }
    ,
    {
        path: '/about',
        name: 'about',
        component: About
    }

]
});
