<template>
    <div class="case">
        <div class="part1 scorllCon" ref="part1">
            <div>
                <ul>
                    <li @click="scrollTop(0)"><img src="../assets/home/part1/school.png" alt="">
                        <p>学校</p>
                    </li>
                    <li @click="scrollTop(1)"><img src="../assets/home/part1/gongchang.png" alt="">
                        <p>工厂</p>
                    </li>
                    <li @click="scrollTop(2)"><img src="../assets/home/part1/gangkou.png" alt="">
                        <p>港口</p>
                    </li>
                    <li @click="scrollTop(3)"><img src="../assets/home/part1/meitan.png" alt="">
                        <p>煤炭</p>
                    </li>
                    <li @click="scrollTop(4)"><img src="../assets/home/part1/dianli.png" alt="">
                        <p>电力</p>
                    </li>
                    <li @click="scrollTop(5)"><img src="../assets/home/part1/yuanqu.png" alt="">
                        <p>园区</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="part2">
            <div class="part2-box scorllCon" v-for="(item, index) in part2Data" :key="index">
                <div class="box-title">{{ item.title }}</div>
                <p :style="{color:item.color}">{{ item.des }}</p>
                <img :src="item.imgUrl" alt="">
            </div>

        </div>
        <div class="part3 scorllCon" ref="part3">
            <div>
                <img src="../assets/home/part5/logo.png" alt="">
                
            </div>
            <div>
                <div>
                    <table>
                        <tr>
                            <th v-for="item, index in tableData.th" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                        <tr v-for="(item, index) in tableData.data" :key="index">
                            <td>{{ item.bigData }}</td>
                            <td>{{ item.apply }}</td>
                            <td>{{ item.resolve }}</td>
                            <td>{{ item.case }}</td>
                            <td>{{ item.about }}</td>

                        </tr>
                    </table>

                </div>
            </div>
            <div>
                <p>©2022-西安幻数科技有限公司 <a href="https://beian.miit.gov.cn/">陕ICP备2022012027号-1</a> </p>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Case',
    data() {
        return {
            tableData: {
                th: ["大数据", "行业案例", "解决方案", "成功案列", "关于我们"],
                data: [
                    {
                        bigData: "",
                        apply: "学校  工厂",
                        resolve: "数字孪生",
                        case: "",
                        about: "公司背景"
                    },
                    {
                        bigData: "",
                        apply: "港口  煤炭",
                        resolve: "三维建模",
                        case: "",
                        about: "联系我们"
                    },
                    {
                        bigData: "",
                        apply: "电力  园区",
                        resolve: "智慧城市",
                        case: "",
                        about: ""
                    }
                ]

            },
            curIndex: 0,
            scorllDomArray: [],
            scorllTimer: null,
            isScorll:true,
            part2Data: [
                {
                    title: "学校",
                    des: "中间基于校园的物理场景，构建精细化三维模型，以校园三维场景为交互中心，以统计图的形式，对学校的整体情况进行分析，包括校园配电室的详情，增强校园安全防控。",
                    imgUrl: require("../assets/case/part2/学校.jpg"),
                    color:"#666666"
                },
                {
                    title: "工厂",
                    des: "该项目以调度集中管控为切入点，实现智能管控，通过数字孪生技术和可视化的展现能力，实现了设备管控、运输管控、和安保管控。构建完整的一体化可视化平台。",
                    imgUrl: require("../assets/case/part2/工厂.jpg"),
                    color:"#ffffff"
                }
                ,
                {
                    title: "港口",
                    des: "结合大数据、物联网、智能应用等综合应用，对港口进行整体3D建模，通过接入物联网系统进行数据与三维场景的联动和数据交互展示，实现数据共享，可以实时查看到物资信息、现场实时监控、设备运行状态等来进行管理。",
                    imgUrl: require("../assets/case/part2/港口.jpg"),
                    color:"#666666"
                }
                ,
                {
                    title: "煤炭",
                    des: "利用生产、运输、销售等各个环节的信息采集，将数据转换成图形，实现三维效果，从而实现煤矿的综合管理和集中控制的系统，在一定程度上，实现了信息管理的自动化，病具有可视化界面。",
                    imgUrl: require("../assets/case/part2/煤炭.jpg"),
                    color:"#ffffff"
                }
                ,
                {
                    title: "电力",
                    des: "对变电站的内外环境进行全方位的建模，来呈现变电站设备的空间关系以及环境数据的实时变化。变电站采用先进、可靠、集成和环保的智能设备，以全站信息数字化、通信平台网络化、信息共享标准化为基本要求，自动完成基本功能。",
                    imgUrl: require("../assets/case/part2/电力.jpg"),
                    color:"#666666"
                },
                {
                    title: "园区",
                    des: "视频监控、人员、车辆、安全管控等系统，场景内实时看到人员车辆的具体位置以及状态，对同一区域内的设备进行关联操作，能够有效地做到事前预警、事中监测、事后分析，大大的提高了园区管理效率，提升园区的安全指数。",
                    imgUrl: require("../assets/case/part2/园区.jpg"),
                    color:"#ffffff"
                }



            ]
        }
    },
    watch:{
        curIndex:{
            handler(val){
                if(val==0){
                    this.$store.state.flag=true
                }else{
                    this.$store.state.flag=false
                }
                
            },
            immediate:true
        }
    },
    mounted(){
        document.getElementById("view").scrollTop = 0
        this.getScorllPosition()
        // document.getElementById("view").addEventListener("mousewheel", this.scorllFun, false)
    },
    methods:{
        scrollTop(x) {
            let a = document.getElementsByClassName("part2-box")
            a[x].scrollIntoView()
            this.curIndex=x+1
        },
        getScorllPosition() {
            let htmlCollection = document.getElementsByClassName("scorllCon")
            for (let i = 0; i < htmlCollection.length; i++) {
                    this.scorllDomArray.push(htmlCollection.item(i))
            }

        },
        scorllFun(e) {
            e.preventDefault && e.preventDefault();
            if (this.scorllDomArray.length == 0 ) {
                return
            }
            if(!this.isScorll){
                return
            }
            this.isScorll=false
            if (e.wheelDelta < 0) {
                    if (this.curIndex + 1 > this.scorllDomArray.length - 1) {
                        this.curIndex = this.scorllDomArray.length - 1
                    } else {
                        this.curIndex = this.curIndex + 1
                    }
                } else {
                    if (this.curIndex - 1 < 0) {
                        this.curIndex = 0
                    } else {
                        this.curIndex = this.curIndex - 1
                    }
                }
                this.scorllDomArray[this.curIndex].scrollIntoView()
                let self=this
            this.scorllTimer = setTimeout(() => {
                self.isScorll=true
                clearTimeout(this.scorllTimer)
            }, 500)

        }
    },
    unmounted(){
        // document.getElementById("view").removeEventListener("mousewheel", this.scorllFun, false)
    }
}
</script>
    
<style scoped>
.case {
    position: relative;
    width: 100%;
    min-width: 1400px;
}

.part1 {
    position: relative;
    z-index: 2;
    top: 0px;
    width: 100%;
    height: 559px;
    background: url(../assets/case/banner.jpg) no-repeat;
    background-size: cover;
}

.part1>p:nth-child(1) {
    font-size: 68px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
    text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.42);
}

.part1>p:nth-child(2) {
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.46);
}


.part1>div {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
}

li {
    cursor: pointer;
    background: url(../assets/home/part1/bt-bg.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 210px;
    height: 120px;
    list-style-type: none;
    text-align: center;
}
li:hover{
    background: url(../assets/apply/part2/selectbg.png);
}
li>p{
    padding-left: 20px;
}

.part2 {
    position: relative;
    margin-bottom: -10px;
    background: url(../assets/case/part2/bg.jpg);
    background: cover;
}

.part2-box {
    padding-top: 110px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.part2-box>p {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 871px;
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 32px;


}
.part2-box>p::before {
    position: absolute;
    top: -10px;
    left: 0px;
    content: "";
    width: 12px;
    height: 12px;
    background: url(../assets/case/part2/before.png);
}
.part2-box>p::after {
    position: absolute;
    bottom: 0px;
    right: 0px;
    content: "";
    width: 12px;
    height: 12px;
    background: url(../assets/case/part2/after.png);
}

.box-title {
    text-align: center;
    width: 357px;
    height: 47px;
    line-height: 47px;
    color: #fff;
    background: url(../assets/case/part2/title.png);
    font-size: 32px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
}
.part3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background: url(../assets/home/part5/bg.png) no-repeat;
    background-size: 100% 100%;
}

.part3>div:nth-child(1) {
    padding-left: 300px;
    padding-right: 300px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
   
}
.part3>div:nth-child(2) {
    width: 80%;
    flex-grow: 0.6;
    margin: auto;
    
}

.part3>div:last-child{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0.2;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.part3>div:nth-child(3)>p {
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgb(252, 254, 255, 0.6);
}

.part3>div>div {
    display: flex;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 155px;
}
.part3 a{
    text-decoration: none;
    color: rgb(252, 254, 255, 0.6);
    cursor: pointer;
}
.part3 a:hover{
    text-decoration: none;
    color: rgba(219, 84, 22, 0.6);
    cursor: pointer;
}
table{
    width: 100%;
   
}

th {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FCFEFF;
    line-height:40px;
}

td {
    text-align: center;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #FCFEFF;
    line-height: 30px;

}
</style>
    