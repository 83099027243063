<template>
    <div class="home">
        <div class="list scorllCon" ref="list" :style="{ width: winwidth * 5 + 'px' }">
            <img src="../assets/home/part1/banner1.jpg" :style="{ width: winwidth + 'px' }" alt="">
            <img src="../assets/home/part1/banner2.jpg" :style="{ width: winwidth + 'px' }" alt="">
            <img src="../assets/home/part1/banner3.jpg" :style="{ width: winwidth + 'px' }" alt="">
            <img src="../assets/home/part1/banner4.jpg" :style="{ width: winwidth + 'px' }" alt="">
            <img src="../assets/home/part1/banner1.jpg" :style="{ width: winwidth + 'px' }" alt="">
        </div>
        <!-- <div class="prev" @click="onPrev"></div>
        <div class="next" @click="onNext"></div> -->
        <ul class="icolist">
            <li @click="imageChange(0)" :class="{ icoHigh: (Math.floor(-listLeft / winwidth)) == 0 }"></li>
            <li @click="imageChange(1)" :class="{ icoHigh: (Math.floor(-listLeft / winwidth)) == 1 }"></li>
            <li @click="imageChange(2)" :class="{ icoHigh: (Math.floor(-listLeft / winwidth)) == 2 }"></li>
            <li @click="imageChange(3)" :class="{ icoHigh: (Math.floor(-listLeft / winwidth)) == 3 }"></li>
        </ul>
        <div class="part1 scorllCon">
            <ul>
                <li><img src="../assets/home/part1/school.png" alt="">
                    <p>学校</p>
                </li>
                <li><img src="../assets/home/part1/gongchang.png" alt="">
                    <p>工厂</p>
                </li>
                <li><img src="../assets/home/part1/gangkou.png" alt="">
                    <p>港口</p>
                </li>
                <li><img src="../assets/home/part1/meitan.png" alt="">
                    <p>煤炭</p>
                </li>
                <li><img src="../assets/home/part1/dianli.png" alt="">
                    <p>电力</p>
                </li>
                <li><img src="../assets/home/part1/yuanqu.png" alt="">
                    <p>园区</p>
                </li>
            </ul>
        </div>
        <div class="part2">
            <div>
                <p>专注于空间可视化效果设计</p>
                <p>“Cesium、UE和数字孪生等发展和对数据的需求”</p>
            </div>
            <div class="title" :style="{ paddingTop: 5 + 'px' }">
                <img src="../assets/home/before.png" alt="">
                <p>三维基础建模和纹理烘培</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <div class="part2-process">
                <img src="../assets/home/part2/connect.png" alt="">
                <div v-for="item, index in animationData" :key="index" :class="item.classStyle">
                    <p>{{ item.text }}</p>
                    <img :src="item.imgUrl" alt="">
                </div>
            </div>
            <div v-for="(item, index) in part2Data" :key="index" class="part2-box"
                :class="[{ boxlast: index == part2Data.length - 1 }, { scorllCon: index % 2 == 0 }]">
                <img :class="item.imageStyle" :src="item.imageUrl" alt="">
                <img class="imgBottom" src="../assets/home/part2/right.png" alt="">
                <div :class="item.contentStyle">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                </div>
            </div>
            <div class="title scorllCon">
                <img src="../assets/home/before.png" alt="">
                <p>机械动画</p>
                <img src="../assets/home/after.png" alt="">
            </div>
        </div>
        <div class="part3">
            <div v-for="(item, index) in part3Data" :key="index">
                <!-- <img :src="item.png" alt=""> -->
                <video width="374" height="484" :src="item.mp4" autoplay controls loop="loop"></video>
                <p class="part3-name">{{ item.name }}</p>
                <p class="part3-des">{{ item.des }}</p>
            </div>
        </div>

        <div class="part4">
            <div class="title scorllCon">
                <img src="../assets/home/before.png" alt="">
                <p>交互动效设计</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <div class="part4-1">
                <div v-for="(item, index) in part4Data" :key="index">
                    <img :src="item.png" alt="" width="355" height="218" loop="3">
                    <p>{{ item.name }}</p>
                </div>
            </div>
            <div class="title" id="lastTitle">
                <img src="../assets/home/before.png" alt="">
                <p>驾驶舱效果图展示</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <div class="part4-2">
                <img src="../assets/home/part4/jiashi.jpg" alt="">
            </div>
        </div>
        <div class="part5 scorllCon">
            <div>
                <img src="../assets/home/part5/logo.png" alt="">

            </div>
            <div>
                <div>
                    <table>
                        <tr>
                            <th v-for="item, index in tableData.th" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                        <tr v-for="(item, index) in tableData.data" :key="index">
                            <td>{{ item.bigData }}</td>
                            <td>{{ item.apply }}</td>
                            <td>{{ item.resolve }}</td>
                            <td>{{ item.case }}</td>
                            <td>{{ item.about }}</td>

                        </tr>
                    </table>

                </div>
            </div>
            <div>
                <p>©2022-西安幻数科技有限公司 <a href="https://beian.miit.gov.cn/">陕ICP备2022012027号</a> </p>
            </div>
        </div>


    </div>
</template>
<script>

/* eslint-disable */
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            animationData: [
                {
                    classStyle: "process-box",
                    imgUrl: require("../assets/home/part2/基础建模icon.png"),
                    text: "基础建模"
                },
                {
                    classStyle: "process-box1",
                    imgUrl: require("../assets/home/part2/纹理贴图icon.png"),
                    text: "纹理贴图"
                },
                {
                    classStyle: "process-box",
                    imgUrl: require("../assets/home/part2/烘培渲染icon.png"),
                    text: "烘培渲染"
                }
                ,
                {
                    classStyle: "process-box1",
                    imgUrl: require("../assets/home/part2/数据处理.png"),
                    text: "数据处理"
                }
            ],
            tableData: {
                th: ["大数据", "行业案例", "解决方案", "成功案列", "关于我们"],
                data: [
                    {
                        bigData: "",
                        apply: "学校  工厂",
                        resolve: "数字孪生",
                        case: "",
                        about: "公司背景"
                    },
                    {
                        bigData: "",
                        apply: "港口  煤炭",
                        resolve: "三维建模",
                        case: "",
                        about: "联系我们"
                    },
                    {
                        bigData: "",
                        apply: "电力  园区",
                        resolve: "智慧城市",
                        case: "",
                        about: ""
                    }
                ]

            },
            part2Data: [
                {
                    title: "基础建模",
                    content: "左图是原模型未经任何处理，在cesiumlab 3中的场景展现。让人们对抽象难懂的空间信息直观化和可视化。",
                    imageStyle: "image-left",
                    contentStyle: "box-right",
                    imageUrl: require("../assets/home/part2/basemodel.jpg")
                },
                {
                    title: "纹理贴图",
                    content: "这是在MAX中经过处理过后的模型，在cesiumlab 3中展现。模型更加真实，更符合人们的习惯。",
                    imageStyle: "image-right",
                    contentStyle: "box-left",
                    imageUrl: require("../assets/home/part2/wenli.jpg")
                },
                {
                    title: "烘培渲染",
                    content: "左图是在cesiumlab 3中的场景展现。其设备与空间位置密切相关，整体的把控也符合现代人的视觉。",
                    imageStyle: "image-left",
                    contentStyle: "box-right",
                    imageUrl: require("../assets/home/part2/hongbei.jpg")
                },

                {
                    title: "数据处理",
                    content: "右图是高级渲染器模拟了真实环境里面光线的各种反射和散射，使其更接近真实的效果。",
                    imageStyle: "image-right",
                    contentStyle: "box-left",
                    imageUrl: require("../assets/home/part2/dataProcess.jpg")
                },


            ],
            part3Data: [
                {
                    name: "机械臂",
                    des: "机械臂是一个复杂系统，存在着参数摄动、外界干扰及未建模动态等不确定性。因而机械臂的建模模型也存在着不确定性。",
                    png: require("../assets/home/part3/jixiebi.png"),
                    mp4: require("../assets/home/part3/jixiebi.mp4")

                },
                {
                    name: "绞索",
                    des: "牵引索， 跨过了各种铁绳绞索,钻过电线和各种氧电焊的线。",
                    png: require("../assets/home/part3/jiaosuo.png"),
                    mp4: require("../assets/home/part3/jiaosuo.mp4")


                },
                {
                    name: "抓斗",
                    des: "抓斗，是指起重机抓取干散货物的专用工具。由两块或多块可启闭的斗状颚板合在一起组成容物空间。",
                    png: require("../assets/home/part3/zhuadou.png"),
                    mp4: require("../assets/home/part3/zhuadou.mp4")

                }
            ],
            part4Data: [
                {
                    name: "监控",
                    png: require("../assets/home/part4/jiankong.png")
                },
                {
                    name: "告警",
                    png: require("../assets/home/part4/gaojing.png")
                },
                {
                    name: "设备",
                    png: require("../assets/home/part4/renyuanshebei.png")
                }
            ],
            winwidth: 1920,
            listLeft: 0,
            timer: null,
            offsetArr: [],
            offIndex: 0,

            curIndex: 0,
            scorllDomArray: [],
            scorllTimer: null,
            isScorll: true

        }
    },
    mounted() {
        document.getElementById("view").scrollTop = 0
        this.winwidth = window.innerWidth
        for (let i = 1; i < 5; i++) {
            this.offsetArr.push(-i * this.winwidth)
        }

        this.runPlay()
        this.getScorllPosition()
        let self = this
        // document.getElementById("view").addEventListener("mousewheel", this.scorllFun, false)
        window.addEventListener("resize", () => {
            self.winwidth = window.innerWidth
            self.offsetArr = []
            for (let i = 1; i < 5; i++) {
                self.offsetArr.push(-i * self.winwidth)
            }
            console.log(self.offsetArr)
        })
    },
    watch: {
        curIndex: {
            handler(val) {
                if (val == 0) {
                    this.$store.state.flag = true
                } else {
                    this.$store.state.flag = false
                }

            },
            immediate: true
        }
    },
    methods: {
        getScorllPosition() {
            let htmlCollection = document.getElementsByClassName("scorllCon")
            for (let i = 0; i < htmlCollection.length; i++) {
                this.scorllDomArray.push(htmlCollection.item(i))
            }

        },
        scorllFun(e) {
            e.preventDefault && e.preventDefault();
            if (this.scorllDomArray.length == 0) {
                return
            }
            if (!this.isScorll) {
                return
            }
            this.isScorll = false
            if (e.wheelDelta < 0) {
                if (this.curIndex + 1 > this.scorllDomArray.length - 1) {
                    this.curIndex = this.scorllDomArray.length - 1
                } else {
                    this.curIndex = this.curIndex + 1
                }
            } else {
                if (this.curIndex - 1 < 0) {
                    this.curIndex = 0
                } else {
                    this.curIndex = this.curIndex - 1
                }
            }
            this.scorllDomArray[this.curIndex].scrollIntoView()
            let self = this
            this.scorllTimer = setTimeout(() => {
                self.isScorll = true
                clearTimeout(this.scorllTimer)
            }, 500)

        },
        runPlay() {
            if (this.listLeft <= -(this.winwidth * 4)) {
                this.listLeft = 0;
                this.offIndex = 0
            }
            if (this.$refs.list) {
                this.$refs.list.style.marginLeft = this.listLeft + "px"
            }
            this.listLeft = this.listLeft - 15
            var n = 1
            // var n = (this.listLeft % this.winwidth == 0) ? n = this.winwidth : n = 10
            if (this.listLeft <= (this.offsetArr[this.offIndex])) {
                // debugger
                n = 2000
                this.listLeft = this.offsetArr[this.offIndex]
                if (this.$refs.list) {
                    this.$refs.list.style.marginLeft = this.listLeft + "px"
                }
                this.offIndex = this.offIndex + 1
            } else {
                n = 1
            }
            this.timer = setTimeout(this.runPlay, n);
        },
        imageChange(n) {
            let lt = -(n * this.winwidth)
            this.$refs.list.style.marginLeft = lt + "px"
            this.listLeft = lt
            this.offIndex = Math.floor(-this.listLeft / this.winwidth)
        },
        // 向上翻页
        onPrev() {
            let prev = Math.floor(-this.listLeft / this.winwidth) - 1
            if (prev == -1) {
                prev = 3
            }
            this.imageChange(prev)
            this.offIndex = prev
        },
        // 向下翻页
        onNext() {
            let next = Math.floor(-this.listLeft / this.winwidth) + 1
            if (next == 4) {
                next = 0
            }
            this.imageChange(next)
            this.offIndex = next
        }

    },
    unmounted() {
        // document.getElementById("view").removeEventListener("mousewheel", this.scorllFun, false)
    }
}
</script>
    
<style scoped>
.home {
    position: relative;
    width: 100%;
    min-width: 1400px;
}

.list {
    overflow: hidden;
    height: 850px;
}

.list img {
    float: left;
    height: 850px;
}

.prev {
    cursor: pointer;
    position: absolute;
    height: 65px;
    width: 35px;
    z-index: 3;
    left: 30px;
    top: 425px;
    transform: translateY(-50%);
    background: url(../assets/home/part1/prev.png);

}

.next {
    cursor: pointer;
    position: absolute;
    height: 65px;
    width: 35px;
    z-index: 3;
    right: 30px;
    top: 425px;
    transform: translateY(-50%);
    background: url(../assets/home/part1/prev.png);
    transform: rotate(180deg);

}

.icolist {
    position: absolute;
    top: 730px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
}

.icoHigh {
    background: #fff !important;
}

.icolist>li {
    cursor: pointer;
    width: 9px;
    margin-left: 20px;
    margin-right: 20px;
    height: 9px;
    border-radius: 50%;
    background: rgb(255, 255, 255, 0.5);
}

.part1 {
    position: absolute;
    left: 50%;
    margin-top: -100px;
    padding-top: 100px;
    transform: translateY(-60px) translateX(-50%);
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
}

li {
    background: url(../assets/home/part1/bt-bg.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 210px;
    height: 120px;
    list-style-type: none;
    text-align: center;
}

li>p {
    padding-left: 20px;
}

.part2 {
    padding-top: 100px;
    background: url(../assets/home/part2/bg.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.part2>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.part2>div:nth-child(1)>p:nth-child(1) {
    font-size: 34px;
    padding: 0px;
    margin: 0px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #1054FF;
    line-height: 40px;
}

.part2>div:nth-child(1)>p:nth-child(2) {
    padding: 0px;
    margin: 15px;
    margin-bottom: 0px;
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #666666;
    line-height: 24px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 28px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
}

.title>p {
    padding-right: 20px;
    padding-left: 20px;
}

.part2-process {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    margin-bottom: 100px;
}

.part2-process>img {
    position: absolute;
    left: calc(50% + 5px);
    bottom: 35px;
    transform: translateX(-50%) translateY(-50%);
}

.process-box {
    position: relative;
    width: 170px;
    margin-right: 65px;
    margin-left: 65px;
    height: 206px;
    background: url(../assets/home/part2/bottom.png);
}

.process-box1 {
    position: relative;
    width: 170px;
    margin-top: 200px;
    margin-right: 55px;
    margin-left: 55px;
    height: 206px;
    background: url(../assets/home/part2/bottom.png);
}

.part2-process>div>p {
    position: absolute;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.part2-process>div>img {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    animation-name: aniImg;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-duration: 2.5s;
}

@keyframes aniImg {
    0% {
        top: 5px;
    }

    50% {
        top: 40px;
    }

    100% {
        top: 5px;
    }

}

.part2-box {
    position: relative;
    margin: auto;
    width: 1240px;
    height: 470px;
    /* background: url(../assets/home/part2/right.png) no-repeat; */
    background-position: left 110%;
    /* background-size: 100% 100%; */
}

.imgBottom {
    position: absolute;
    z-index: 0;
    bottom: 0px !important;
}




.image-left {
    position: absolute;
    bottom: 20px;
    left: 0px;
    z-index: 2;
}

.image-right {
    position: absolute;
    bottom: 20px;
    right: 0px;
    z-index: 2;
}

.part2-box>.box-right {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    right: 80px;
    width: 400px;
}

.part2-box>.box-left {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    left: 80px;
    width: 400px;
}

.part2-box>div>p:nth-child(1) {
    font-size: 32px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
}

.part2-box>div>p:nth-child(2) {
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
}

.part3-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.part3 {
    margin-top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 60px;
    /* height: 900px; */
    background: url(../assets/home/part3/bg.jpg);
}

.part3>div {
    width: 374px;
    height: 650px;
    background: #fff;

}

.part3>div:nth-child(2) {
    margin-right: 50px;
    margin-left: 50px;
}



.part3-name {
    padding-top: 5px;
    margin: auto;
    width: 100px;
    border-bottom: 2px solid #3bbef5;
    font-size: 20px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #666666;
    line-height: 36px;
    text-align: center;
}

.part3-des {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    padding-left: 25px;
    padding-right: 18px;
}

.part4 {
    width: 100%;
    background: url(../assets/home/part4/bg.jpg) no-repeat;
}

.part4-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 267px;
}

.part4-1>div {
    padding: 40px;
    padding-top: 20px;
    box-sizing: border-box;
    background: #fff;
    background: url(../assets/apply/part2/bg2.png);
    background-size: 100% 100%;
}

.part4-1 p {
    text-align: center;
    font-size: 20px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #000000;
    padding-bottom: 20px;
    margin: 0px;
}


.part4-2 {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
}

.part5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background: url(../assets/home/part5/bg.png) no-repeat;
    background-size: 100% 100%;
}

.part5>div:nth-child(1) {
    padding-left: 300px;
    padding-right: 300px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;

}

.part5>div:nth-child(2) {
    width: 80%;
    flex-grow: 0.6;
    margin: auto;

}

.part5>div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0.2;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.part5>div:nth-child(3)>p {
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgb(252, 254, 255, 0.6);
}

.part5>div>div {
    display: flex;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 155px;

}

.part5 a {
    text-decoration: none;
    color: rgb(252, 254, 255, 0.6);
    cursor: pointer;
}

.part5 a:hover {
    text-decoration: none;
    color: rgba(219, 84, 22, 0.6);
    cursor: pointer;
}

table {
    width: 100%;

}

th {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FCFEFF;
    line-height: 40px;
}

td {
    text-align: center;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #FCFEFF;
    line-height: 30px;

}

#lastTitle {
    padding-top: 10px;
}
</style>
    