<template>
    <div class="apply" id="apply">
        <div class="part1 scorllCon" ref="part1">
            <div>
                <ul>
                    <li @click="scrollTop(0)"><img src="../assets/home/part1/school.png" alt="">
                        <p>学校</p>
                    </li>
                    <li @click="scrollTop(1)"><img src="../assets/home/part1/gongchang.png" alt="">
                        <p>工厂</p>
                    </li>
                    <li @click="scrollTop(2)"><img src="../assets/home/part1/gangkou.png" alt="">
                        <p>港口</p>
                    </li>
                    <li @click="scrollTop(3)"><img src="../assets/home/part1/meitan.png" alt="">
                        <p>煤炭</p>
                    </li>
                    <li @click="scrollTop(4)"><img src="../assets/home/part1/dianli.png" alt="">
                        <p>电力</p>
                    </li>
                    <li @click="scrollTop(5)"><img src="../assets/home/part1/yuanqu.png" alt="">
                        <p>园区</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="part2">
            <div class="part2-box scorllCon" v-for="(item, index) in part2Data" :key="index">
                <div class="apply-des" :class="item.classStyle">
                    <img :class="item.imgStyle" :src="item.applyUrl" alt="">
                    <div :class="item.desStyle">
                        <p>{{ item.index }}</p>
                        <img src="../assets/apply/part2/line.jpg" alt="">
                        <p>{{ item.scene }}</p>
                        <p>{{ item.des }}</p>
                    </div>
                </div>
                <p>应用场景</p>
                <div class="apply-scene">
                    <div v-for="(scene, i) in item.applyscenes" :key="i">
                        <img src="../assets/apply/part2/bg2.png" alt="">
                        <img :src="scene.imgUrl" alt="">
                        <p>{{ scene.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="part3 scorllCon" ref="part3">
            <div>
                <img src="../assets/home/part5/logo.png" alt="">

            </div>
            <div>
                <div>
                    <table>
                        <tr>
                            <th v-for="item, index in tableData.th" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                        <tr v-for="(item, index) in tableData.data" :key="index">
                            <td>{{ item.bigData }}</td>
                            <td>{{ item.apply }}</td>
                            <td>{{ item.resolve }}</td>
                            <td>{{ item.case }}</td>
                            <td>{{ item.about }}</td>

                        </tr>
                    </table>

                </div>
            </div>
            <div>
                <p>©2022-西安幻数科技有限公司 <a href="https://beian.miit.gov.cn/">陕ICP备2022012027号-1</a> </p>
            </div>
        </div>

    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Apply',
    components: {
    },
    data() {
        return {
            tableData: {
                th: ["大数据", "行业案例", "解决方案", "成功案列", "关于我们"],
                data: [
                    {
                        bigData: "",
                        apply: "学校  工厂",
                        resolve: "数字孪生",
                        case: "",
                        about: "公司背景"
                    },
                    {
                        bigData: "",
                        apply: "港口  煤炭",
                        resolve: "三维建模",
                        case: "",
                        about: "联系我们"
                    },
                    {
                        bigData: "",
                        apply: "电力  园区",
                        resolve: "智慧城市",
                        case: "",
                        about: ""
                    }
                ]

            },
            curIndex: 0,
            scorllDomArray: [],
            scorllTimer: null,
            isScorll: true,
            part2Data: [
                {
                    index: "01",
                    applyUrl: require("../assets/apply/part2/学校.jpg"),
                    classStyle: "box-left",
                    imgStyle: "image-left",
                    desStyle: "des-right",
                    scene: "学校",
                    des: "在虚拟空间中再造一个与现实学校匹配对应的虚拟学校，推动学校全要素数字化和虚拟化、全状态实时化和可视化、城市运行管理协同化智能化，实现现实学校与虚拟学校协同交互、平行运转。",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/学校-1.jpg"),
                            name: "校园交通识别检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/学校-2.jpg"),
                            name: "校园垃圾识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/学校-3.jpg"),
                            name: "人员聚集检测"
                        }

                    ]

                },
                {
                    index: "02",
                    applyUrl: require("../assets/apply/part2/工厂.jpg"),
                    classStyle: "box-right",
                    imgStyle: "image-right",
                    desStyle: "des-left",
                    scene: "工厂",
                    des: "以物联网、大数据、可视化等技术，完成对工厂三维一体的数字孪生模型建设，以及对人员、车辆、应急物资、重点设施设备重点监控对象的位置感知，电流、电压、温度、压力、报警阈值等重点设备运行数据监控。",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/工厂-1.jpg"),
                            name: "烟雾识别检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/工厂-2.jpg"),
                            name: "抽烟识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/工厂-3.jpg"),
                            name: "人员聚集检测"
                        }

                    ]

                },
                {
                    index: "03",
                    applyUrl: require("../assets/apply/part2/港口.jpg"),
                    classStyle: "box-left",
                    imgStyle: "image-left",
                    desStyle: "des-right",
                    scene: "港口",
                    des: "以可视化引擎为依托，服务于港口设备工作进程，为各类设备体提供可用数据驱动的模型，推动行业数字化转型与智慧应用。可以完成港口从规划设计到施工运维全生命周期管理",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/港口-1.jpg"),
                            name: "船舶识别检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/港口-2.jpg"),
                            name: "库房识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/港口-3.jpg"),
                            name: "车辆聚集检测"
                        }

                    ]

                },
                {
                    index: "04",
                    applyUrl: require("../assets/apply/part2/煤炭.jpg"),
                    classStyle: "box-right",
                    imgStyle: "image-right",
                    desStyle: "des-left",
                    scene: "煤炭",
                    des: "基于虚拟现实技术,信息技术,网络技术等,构建煤炭可视化管控平台，通过与信息管理系统生产运营数据的对接,以及生产运营过程中设备的快速定位，通过这个工程应用，验证了该系统的先进性和实用性。",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/煤炭-1.jpg"),
                            name: "车辆识别检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/煤炭-2.jpg"),
                            name: "厂房识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/煤炭-3.jpg"),
                            name: "工人检测"
                        }

                    ]

                },
                {
                    index: "05",
                    applyUrl: require("../assets/apply/part2/电力.jpg"),
                    classStyle: "box-left",
                    imgStyle: "image-left",
                    desStyle: "des-right",
                    scene: "电力",
                    des: "基于数字孪生技术对电网主要设备、厂站与环境精细三维全景仿真，实现与采集数据实时交互，能够在仿真场景中动态融合展示设备与关键传感数据。通过在厂站内部署的视频网络和传感器网络，实时反馈环境变化、并进行实时分析。",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/电力-1.jpg"),
                            name: "车辆识别检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/电力-2.jpg"),
                            name: "厂房识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/电力-3.jpg"),
                            name: "工人检测"
                        }

                    ]

                }
                ,
                {
                    index: "06",
                    applyUrl: require("../assets/apply/part2/园区.png"),
                    classStyle: "box-right",
                    imgStyle: "image-right",
                    desStyle: "des-left",
                    scene: "园区",
                    des: "绿色、高端、智能、创新、互联网+、转型升级....成为产业园区发展的关键词，这也预示着未来地产与产业融合的发展方向。通过基础设施的数据收集，管理的精细提升，园区服务的专业匹配以及园区体验的智能提升，是园区“未来时代”的智慧服务。",
                    applyscenes: [
                        {
                            imgUrl: require("../assets/apply/part2/园区-1.png"),
                            name: "防火检测"
                        },
                        {
                            imgUrl: require("../assets/apply/part2/园区-2.png"),
                            name: "监控识别检测"
                        }, {
                            imgUrl: require("../assets/apply/part2/园区-3.png"),
                            name: "巡检检测"
                        }

                    ]

                }
            ]
        }
    },
    mounted() {
        document.getElementById("view").scrollTop = 0
        this.getScorllPosition()
        // document.getElementById("view").addEventListener("mousewheel", this.scorllFun, false)

    },
    watch: {
        curIndex: {
            handler(val) {
                if (val == 0) {
                    this.$store.state.flag = true
                } else {
                    this.$store.state.flag = false
                }

            },
            immediate: true
        }
    },
    methods: {
        scrollTop(x) {
            let a = document.getElementsByClassName("part2-box")
            a[x].scrollIntoView()
            this.curIndex = x + 1
        },
        getScorllPosition() {
            let htmlCollection = document.getElementsByClassName("scorllCon")
            for (let i = 0; i < htmlCollection.length; i++) {
                this.scorllDomArray.push(htmlCollection.item(i))
            }

        },
        scorllFun(e) {
            e.preventDefault && e.preventDefault();
            if (this.scorllDomArray.length == 0) {
                return
            }
            if (!this.isScorll) {
                return
            }
            this.isScorll = false
            if (e.wheelDelta < 0) {
                if (this.curIndex + 1 > this.scorllDomArray.length - 1) {
                    this.curIndex = this.scorllDomArray.length - 1
                } else {
                    this.curIndex = this.curIndex + 1
                }
            } else {
                if (this.curIndex - 1 < 0) {
                    this.curIndex = 0
                } else {
                    this.curIndex = this.curIndex - 1
                }
            }
            this.scorllDomArray[this.curIndex].scrollIntoView()
            let self = this
            this.scorllTimer = setTimeout(() => {
                self.isScorll = true
                clearTimeout(this.scorllTimer)
            }, 500)


        },
        DropScroll(e) {
            console.log(e)
        },


    },
    unmounted() {
        // document.getElementById("view").removeEventListener("mousewheel", this.scorllFun, false)
    }
}
</script>
    
<style scoped>
p {
    padding: 0px;
    margin: 0px;
}

.apply {
    position: relative;
    width: 100%;
    min-width: 1400px;
}

.part1 {
    position: relative;
    z-index: 2;
    top: 0px;
    width: 100%;
    height: 559px;
    background: url(../assets/apply/banner.jpg);
    background-size: cover;
}

.part1>p:nth-child(1) {
    font-size: 68px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
    text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.42);
}

.part1>p:nth-child(2) {
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.46);
}


.part1>div {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
}

li {
    background: url(../assets/home/part1/bt-bg.png);
    cursor: pointer;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 210px;
    height: 120px;
    list-style-type: none;
    text-align: center;
}

li:hover {
    background: url(../assets/apply/part2/selectbg.png);
}

li>p {
    padding-left: 20px;
}

.part2 {
    position: relative;
    padding-top: 10px;
    background-position: left top;
    z-index: -1;
    width: 100%;
    padding-bottom: 50px;
    background: url(../assets/apply/part2/bg.jpg);
    background-size: 100% 100%;
}

.part2-box {
    margin: auto;
    width: 1245px;
    padding-top: 100px;
}

.part2-box>p {
    text-align: center;
    font-size: 24px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #4F99FD;
    line-height: 33px;
    padding-top: 80px;
    padding-bottom: 0px;
    margin-bottom: 20px;
}

.apply-des {
    width: 100%;
    height: 400px;
    position: relative;

}

.apply-des>div>p:first-child {
    padding-bottom: 30px;
    font-size: 70px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #E2E4E4;
    line-height: 30px;
}

.apply-des>div>p:nth-child(3) {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
}

.apply-des>div>p:last-child {
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
}

.image-left {
    position: relative;
    display: block;
    left: 20px;

}

.box-left::before {
    position: absolute;
    bottom: -10px;
    left: 0px;
    content: "";
    width: 200px;
    height: 200px;
    background: #4f99fd;
}

.image-right {
    position: absolute;
    right: 20px;


}

.box-right::before {
    position: absolute;
    bottom: -10px;
    right: 0px;
    content: "";
    width: 200px;
    height: 200px;
    background: #4f99fd;
}

.des-right {
    position: absolute;
    width: 329px;
    text-align: left;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
}

.des-left {
    position: absolute;
    width: 329px;
    text-align: left;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}

.des-right>p:last-child {
    text-align: left;
   
}



.apply-scene {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.apply-scene>div {
    position: relative;
}

.apply-scene>div>img:nth-child(1) {
    position: absolute;
    z-index: -1;
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
}

.apply-scene>div>img:last-child {
    display: inline-block;
}

.apply-scene>div>p {
    padding-top: 5px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
}


.part3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background: url(../assets/home/part5/bg.png) no-repeat;
    background-size: 100% 100%;
}

.part3>div:nth-child(1) {
    padding-left: 300px;
    padding-right: 300px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;

}

.part3>div:nth-child(2) {
    width: 80%;
    flex-grow: 0.6;
    margin: auto;

}

.part3>div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0.2;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.part3>div:nth-child(3)>p {
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgb(252, 254, 255, 0.6);
}

.part3>div>div {
    display: flex;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 155px;
}

.part3 a {
    text-decoration: none;
    color: rgb(252, 254, 255, 0.6);
    cursor: pointer;
}

.part3 a:hover {
    text-decoration: none;
    color: rgba(219, 84, 22, 0.6);
    cursor: pointer;
}

table {
    width: 100%;

}

th {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FCFEFF;
    line-height: 40px;
}

td {
    text-align: center;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #FCFEFF;
    line-height: 30px;

}
</style>
    