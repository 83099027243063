<template>
    <div class="about">
        <div class="part1">
            <div class="title">
                <img src="../assets/home/before.png" alt="">
                <p>公司背景</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <div class="part1-content">
                <p>西安幻数科技有限公司为北京西部世界科技有限公司的关联公司，北京西部世界科技有限公司是业内知名的空间大数据产品研发团队，目前有CesiumLab、EarthSDK、PipeSer多款流行的三维数据轻量化引擎和平台型产品，累计注册行业用户20000多人，覆盖超过1000家企事业单位。
                    西安幻数依托西部世界在数字孪生、智慧城市、空间大数据可视化方向的技术积累和产品积累，结合云渲染技术、物联网技术，重点打造基于游戏引擎的数字孪生数据生产平台，重点满足自然资源领域国家实景三维中国建设，住房和城建领域CIM平台，智慧工厂、智慧电力等行业用户对于三维数字孪生的技术和数据需求。
                </p>
            </div>
            <div class="title">
                <img src="../assets/home/before.png" alt="">
                <p>企业文化</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <div class="part1-animation">
                <div v-for="item, index in animationData" :key="index" class="ani-box">
                    <p>{{ item.text }}</p>
                    <p>{{ item.content }}</p>
                    <img :src="item.imgUrl" alt="">
                </div>
            </div>
        </div>
        <div class="part2">
            <div class="title">
                <img src="../assets/home/before.png" alt="">
                <p>联系我们</p>
                <img src="../assets/home/after.png" alt="">
            </div>
            <p>扫码添加 获得更多信息</p>
            <div>
                <img src="../assets/about/part2/bg.jpg" alt="">
            </div>
        </div>
        <div class="part3">
            <div>
                <img src="../assets/home/part5/logo.png" alt="">

            </div>
            <div>
                <div>
                    <table>
                        <tr>
                            <th v-for="item, index in tableData.th" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                        <tr v-for="(item, index) in tableData.data" :key="index">
                            <td>{{ item.bigData }}</td>
                            <td>{{ item.apply }}</td>
                            <td>{{ item.resolve }}</td>
                            <td>{{ item.case }}</td>
                            <td>{{ item.about }}</td>
                        </tr>
                    </table>

                </div>
            </div>
            <div>
                <p>©2022-西安幻数科技有限公司 <a href="https://beian.miit.gov.cn/">陕ICP备2022012027号-1</a> </p>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'About',
    components: {
    },
    data() {
        return {
            animationData: [
                {
                    imgUrl: require("../assets/about/宗旨.png"),
                    text: "宗旨",
                    content: "效率成就品牌，诚信铸就未来"
                },
                {
                    imgUrl: require("../assets/about/信念.png"),
                    text: "信念",
                    content: "我愿与你同行，因你势不可挡"
                }
                ,
                {
                    imgUrl: require("../assets/about/文化.png"),
                    text: "文化",
                    content: "实施战略创信誉，培育企业文化树形象。"
                },
                {

                    imgUrl: require("../assets/about/精神.png"),
                    text: "精神",
                    content: "自立自强、同心同德、创优创新"
                },



            ],
            tableData: {
                th: ["大数据", "行业案例", "解决方案", "成功案列", "关于我们"],
                data: [
                    {
                        bigData: "",
                        apply: "学校  工厂",
                        resolve: "数字孪生",
                        case: "",
                        about: "公司背景"
                    },
                    {
                        bigData: "",
                        apply: "港口  煤炭",
                        resolve: "三维建模",
                        case: "",
                        about: "联系我们"
                    },
                    {
                        bigData: "",
                        apply: "电力  园区",
                        resolve: "智慧城市",
                        case: "",
                        about: ""
                    }
                ]

            }
        }
    },
    mounted() {
        document.getElementById("view").scrollTop = 0
        this.$store.state.flag = true
        document.getElementById("view").addEventListener("mousewheel", this.scorllFun, false)
    },
    methods: {
        scorllFun(e) {
            document.getElementById("view").scrollTop < 900 ? this.$store.state.flag = true : this.$store.state.flag = false
        },
    },
    unmounted() {
        document.getElementById("view").removeEventListener("mousewheel", this.scorllFun, false)
    }
}
</script>
    
<style scoped>
.about {
    width: 100%;
    min-width: 1400px;
}

.part1 {
    width: 100%;
    background: url(../assets/about/bg.jpg) no-repeat;
    padding-top: 300px;

}

.part1-content {
    padding: 30px;
    box-sizing: border-box;
    width: 1218px;
    height: 286px;
    margin: auto;
    background: url(../assets/about/part1/center.png) no-repeat;
    background-size: cover;
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    font-size: 28px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
}

.part1>div:last-child {
    margin-top: 30px;
    padding-bottom: 150px;
}

.part1-animation {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ani-box {
    position: relative;
    width: 96px;
    margin-right: 105px;
    margin-left: 105px;
    height: 126px;
    background: url(../assets/about/bottom.png);
}

.ani-box>p:first-child {
    position: absolute;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.ani-box>p:nth-child(2) {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: -100px;
    transform: translateX(-50%);
    width: 300px;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.ani-box>img {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    animation-name: aniImg;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-duration: 2.5s;
}

@keyframes aniImg {
    0% {
        top: 0px;
    }

    50% {
        top: 15px;
    }

    100% {
        top: 0px;
    }

}

.title>p {
    padding-right: 20px;
    padding-left: 20px;
}

.part2 {
    margin-bottom: 50px;
    background: url(../assets/about/part2/背景.png);
}

.part2>p {
    width: 300px;
    text-align: center;
    margin: auto;
    padding: 30px;
    font-size: 20px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 30px;
}

.part2>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 210px;
    margin: auto;
    background: url(../assets/about/part2/bg.jpg);
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
}

.part3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background: url(../assets/home/part5/bg.png) no-repeat;
    background-size: 100% 100%;
}

.part3>div:nth-child(1) {
    padding-left: 300px;
    padding-right: 300px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;

}

.part3>div:nth-child(2) {
    width: 80%;
    flex-grow: 0.6;
    margin: auto;

}

.part3>div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0.2;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.part3>div:nth-child(3)>p {
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgb(252, 254, 255, 0.6);
}

.part3>div>div {
    display: flex;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 155px;
}

.part3 a {
    text-decoration: none;
    color: rgb(252, 254, 255, 0.6);
    cursor: pointer;
}

.part3 a:hover {
    text-decoration: none;
    color: rgba(219, 84, 22, 0.6);
    cursor: pointer;
}

table {
    width: 100%;

}

th {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #FCFEFF;
    line-height: 40px;
}

td {
    text-align: center;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #FCFEFF;
    line-height: 30px;

}
</style>
    