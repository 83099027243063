<template>
   <div class="view" id="view">
    <router-view></router-view>
   </div>
  <Header></Header>
</template>
<script>
import Header from "./components/Header.vue"
export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
.view{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
</style>
