import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index"
// import "./assets/css/index.css"
import store from "./store/index"

let app=createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
