
import Vuex from 'vuex'

 
export default new Vuex.Store({
  //数据，相当于data
  state: {
    flag:true
  },
  getters: {
    
  },
  //里面定义方法，操作state方发
  mutations: {
    
  },
  // 操作异步操作mutation
  actions: {
    
  },
  modules: {
    
  },
})